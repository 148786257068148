<template>
  <b-modal
    id="modal-accounts-receivable"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal"
  >
    <keep-alive>
      <components :is="comp" />
    </keep-alive>
  </b-modal>
</template>

<script>
import {
  BButton, BModal, BRow, BCol, BContainer, BTable, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'

import AddAccountReceivable from '@/components/AccountsReceivable/Modal/Part/Add.vue'
import DetailAccountReceivable from '@/components/Customer/Modal/Part/DetailCustomer.vue'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BContainer,
    BTable,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    AddAccountReceivable,
    DetailAccountReceivable,
  },
  props: {
    is_edit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      comp: 'AddAccountReceivable',
      selected: [],
      options: ['Baik', 'Jelek'],
    }
  },
  watch: {
    is_edit(value) {
      if (value === true) {
        this.comp = 'AddAccountReceivable'
      } else {
        this.comp = 'DetailAccountReceivable'
      }
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

#modal-accounts-receivable {
  table {
    thead {
      tr {
        th {
          border-bottom: 1px solid #ebe9f1;
          background-color: transparent;
          border-top: none;
          padding: 1.5rem 0;
          color: #7A7F94;
          text-transform: capitalize;
          font-size: 14px;

          &:nth-child(4) {
            width: 125px;
            padding-right: 1.5rem;
          }
          &:nth-child(5) {
            width: 100px;
          }
          &:nth-child(8) {
            width: 15rem;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          color: $dark;
          font-size: 14px;
          font-weight: 600;
          padding: 0.5rem 0;
          border-bottom: 1px solid #ebe9f1;
        }
      }
    }
  }
  .footer__purchase {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 16px 16px 0px 0px;
    z-index: 99;
  }
}
</style>
