<template>
  <div class="table__container">
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <b-img
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
          />
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input
              placeholder="Cari nama produk induk"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <vue-good-table
      max-height="66vh"
      :columns="columns"
      :rows="rows"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <div class="d-flex">
            <b-button
              class="mr-1 px-1 bg-white border-8"
              @click="$bvModal.show('modal-accounts-receivable'), is_edit = false"
            >
              Profile
            </b-button>
            <b-button
              class="bg-success-2 border-0 border-8"
              @click="$bvModal.show('modal-accounts-receivable'), is_edit = true"
            >
              Bayar
            </b-button>
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div
          class="d-flex justify-content-between align-items-center flex-wrap px-2 bg-white border"
          style="height:80px"
        >
          <div class="d-flex align-items-center mb-1 mt-1">
            <b-form-select
              v-model="pageLength"
              :options="['10','15','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">
              Tampilkan 1 ke {{ pageLength }} dari {{ props.total }} transaksi.
            </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  style="color: #6B6C7E"
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  style="color: #6B6C7E"
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <ModalAccountsReceivable :is_edit="is_edit" />
  </div>
</template>

<script>
import {
  BPagination, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton,
} from 'bootstrap-vue'

import ModalAccountsReceivable from '@/components/AccountsReceivable/Modal/ModalAccountsReceivable.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    ModalAccountsReceivable,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      is_edit: false,
      pageLength: 10,
      columns: [
        {
          label: 'Nama',
          field: 'nama',
        },
        {
          label: 'No. Telepon',
          field: 'no_telp',
        },
        {
          label: 'Piutang Aktif',
          field: 'piutang_aktif',
        },
        {
          label: 'Piutang Jatuh Tempo',
          field: 'piutang_tempo',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
    }
  },
  created() {
    this.$http.get('/piutang')
      .then(res => { this.rows = res.data })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.vgt-responsive {
  height: calc(100vh - 235px) !important;
  background-color: #fff;
}
</style>
