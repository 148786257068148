<template>
  <div>
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col
          cols="3"
          class="d-flex align-items-center"
        >
          <div
            style="cursor: pointer;"
            @click="$bvModal.hide('modal-accounts-receivable')"
          >
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-center align-items-center"
        >
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            No Invoice: INV/XXII/V/000348
          </h1>
        </b-col>
        <b-col
          v-if="is_edit == true"
          cols="3"
          class="text-right"
        >
          <b-button class="px-3 border-8">
            Update Piutang
          </b-button>
        </b-col>
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <b-row
        class="mx-0 mt-1 justify-content-center"
      >
        <b-col
          class=""
          cols="12"
        >
          <b-table
            class="mb-0"
            responsive="sm"
            :items="items"
          >
            <template #head(action)="props">
              <b-form-checkbox
                v-model="selected"
                value="A"
              />
            </template>
            <!-- A custom formatted column -->
            <template #cell(action)="data">
              <b-form-checkbox
                v-model="selected"
                value="A"
              />
            </template>

            <!-- A custom formatted column -->
            <template #cell(bayar)="data">
              <b-form-group
                label=""
                label-for="v-jumlah-retur"
                class="custom__form--input"
              >
                <b-form-input
                  id="v-jumlah-retur"
                  type="text"
                  :value="data.item.bayar"
                  placeholder="Jumlah Retur"
                  class="custom__input border-8"
                />
              </b-form-group>
            </template>
          </b-table>

        </b-col>
        <b-col md="6" />
        <b-col
          md="6"
          class="border-left border-bottom py-5 pl-3"
        >
          <b-row>
            <b-col cols="4">
              <h6 class="text-dark fw-bold-600 mb-3 text-center">
                Total Piutang
              </h6>
            </b-col>
            <b-col cols="8">
              <span class="text-dark fw-bold-600 mb-3">Rp. 345.000</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <h6 class="text-dark fw-bold-600 mb-3 text-center">
                Total Sudah Dibayar
              </h6>
            </b-col>
            <b-col cols="8">
              <span class="text-dark fw-bold-600 mb-3">Rp. 0</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <h6 class="text-dark fw-bold-600 mb-3 text-center">
                Total Bayar
              </h6>
            </b-col>
            <b-col cols="8">
              <span class="text-dark fw-bold-800 mb-3">Rp. 345.000</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="footer__purchase p-2">
        <b-row class="mx-0 align-items-center">
          <b-col lg="6">
            <h5 class="mb-0">
              Isi kolom bayar untuk mulai pembayaran
            </h5>
          </b-col>
          <b-col
            lg="6"
            class="text-right"
          >
            <div>
              <b-button
                class="btn-primary px-5 py-2 size14 mt-0 border-8"
                @click="$router.push({ name: 'payment.index' })"
              >
                <span class="px-3">Lanjut Pembayaran</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BContainer, BTable, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BContainer,
    BTable,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  data() {
    return {
      items: [
        {
          action: '', 'no.invoice': 'INV/PN/XXII/III/000390', tgl_invoice: '15 Mar 2022', tgl_surat_jalan_supplier: '16 Mar 2022', tgl_jatuh_tempo: '16 Mar 2022', jumlah: 'Rp. 240.000', sisa_piutang: 'Rp 2.000.000', bayar: 'Rp 2.000.000',
        },
        {
          action: '', 'no.invoice': 'INV/PN/XXII/III/000390', tgl_invoice: '15 Mar 2022', tgl_surat_jalan_supplier: '16 Mar 2022', tgl_jatuh_tempo: '16 Mar 2022', jumlah: 'Rp. 0', sisa_piutang: 'Rp 2.000.000', bayar: 'Rp 0.00',
        },
      ],
    }
  },
}
</script>
